<template>
<div>
  <PageHeaderLayout>
    <div class="main-page-content">
      <TrendChart ref="refTrendRank"></TrendChart>
    </div>
  </PageHeaderLayout>
</div>
</template>

<script>
import TrendChart from '@/pages/dashboard/trend/TrendChart'
export default {
  name: 'TrendRank',
  components: { TrendChart }
}
</script>

<style scoped>

</style>
